.markdown-alert {
	/* Border Color */
	--color-border-default: #d0d7de;

	/* Accent Colors */
	--color-accent-fg: #0969da;
	--color-accent-emphasis: #0969da;
	--color-accent-bg: #e6f0ff;
	/* Light blue background */

	/* Danger Colors */
	--color-danger-fg: #d1242f;
	--color-danger-emphasis: #cf222e;
	--color-danger-bg: #ffe6e6;
	/* Light red/pink background */

	/* Attention Colors */
	--color-attention-fg: #9a6700;
	--color-attention-emphasis: #9a6700;
	--color-attention-bg: #fff4e6;
	/* Light orange background */

	/* Done Colors */
	--color-done-fg: #8250df;
	--color-done-emphasis: #8250df;
	--color-done-bg: #f0e6ff;
	/* Light purple background */

	/* Success Colors */
	--color-success-fg: #1a7f37;
	--color-success-emphasis: #1f883d;
	--color-success-bg: #e6f4ea;
	/* Light green background */
}

blockquote {
	border-left: none !important; 
	overflow: hidden; 
	position: relative;
}

blockquote::before {
	content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background-color: var(--borderColor-default, var(--color-border-default));
    border-radius: 10px; 
}

.markdown-alert {
    position: relative;
    border-left: none !important; 
    overflow: hidden; 
}

.markdown-alert::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background-color: var(--borderColor-default, var(--color-border-default));
    border-radius: 10px; 
}


.markdown-alert>:last-child {
	margin-bottom: 0 !important
}

.markdown-alert .markdown-alert-title {
	align-items: center;
	display: flex;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
    margin-top: 5px;
}

.markdown-alert .markdown-alert-title svg.octicon {
	margin-right: 8px !important;
	margin-right: var(--base-size-8, 8px) !important;
	fill: currentColor;
}

.markdown-alert.markdown-alert-note .markdown-alert-title {
	color: var(--color-accent-fg);
	color: var(--fgColor-accent, var(--color-accent-fg))
}

.markdown-alert.markdown-alert-tip .markdown-alert-title {
	color: var(--color-success-fg);
	color: var(--fgColor-success, var(--color-success-fg))
}


.markdown-alert.markdown-alert-important .markdown-alert-title {
	color: var(--color-done-fg);
	color: var(--fgColor-done, var(--color-done-fg))
}

.markdown-alert.markdown-alert-warning .markdown-alert-title {
	color: var(--color-attention-fg);
	color: var(--fgColor-attention, var(--color-attention-fg))
}

.markdown-alert.markdown-alert-caution .markdown-alert-title {
	color: var(--color-danger-fg);
	color: var(--fgColor-danger, var(--color-danger-fg))
}

.markdown-alert.markdown-alert-note::before {
    background-color: var(--borderColor-accent-emphasis, var(--color-accent-emphasis));
}

.markdown-alert.markdown-alert-tip::before {
    background-color: var(--borderColor-success-emphasis, var(--color-success-emphasis));
}

.markdown-alert.markdown-alert-important::before {
    background-color: var(--borderColor-done-emphasis, var(--color-done-emphasis));
}

.markdown-alert.markdown-alert-warning::before {
    background-color: var(--borderColor-attention-emphasis, var(--color-attention-emphasis));
}

.markdown-alert.markdown-alert-caution::before {
    background-color: var(--borderColor-danger-emphasis, var(--color-danger-emphasis));
}
